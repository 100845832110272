@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .toggle-input {
    @apply text-black dark:text-white border border-grayish focus:border-black dark:focus:border-white;
  }

  .toggle-border {
    @apply border border-black dark:border-white;
  }

  .toggle-bg {
    @apply bg-black dark:bg-white text-white dark:text-black;
  }

  .toggle-text {
    @apply text-black dark:text-white;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rating {
  overflow: visible;
}

.gelatine {
  animation: gelatine 0.5s;
}
@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
